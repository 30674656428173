import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    token: sessionStorage.getItem("token") || "",
    users: [],

    userInfo: sessionStorage.getItem("userInfo") || null,
    searchingUser: false,
    creatingQuotation: null,
    errorQuotation: false,
    offers: [],
    products: [],
    categories: [],
    productDetail: {},
    planPago: [],
    brands: [],
    models: [],
    product_categories: [],
    quotation_validation: null,
    numberQuotation: null,
    offersQuotation: null,
    quotationState: null,
    convenio: sessionStorage.getItem("convenio") || "inalco.mesos.cl",
    productDetailFORUM: {},
    productsFORUM: [],
  },
  actions,
  mutations,
  getters,
};

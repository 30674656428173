import axios from "axios";
const URL_API_NEGOCIO = process.env.VUE_APP_BASE_URL_FUNCIONAL_NEGOCIO;
const labelDiaPago = "Día de pago";
const actions = {
  createPAT(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/medio-pago/PAT`,
        method: "POST",
        data: data.data_PAT,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createPAC(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/medio-pago/PAC`,
        method: "POST",
        data: data.data_PAC,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getPAC({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/medio-pago/PAC`,
        method: "GET",
      })
        .then((res) => {
          const bancos = [],
            cuentas = [],
            dias = [],
            result = res.data.data,
            infoBancaria = result?.informacion_bancaria?.[0] ?? {};

          const labelBanco = "Banco";
          const labelTipoCuenta = "Tipo de cuenta";

          (infoBancaria?.bancos ?? []).forEach((it) => {
            bancos.push({ label: it.banco_descripcion, value: it.banco });
          });
          (infoBancaria?.tipos_cuentas ?? []).forEach((it) =>
            cuentas.push({
              label: it.tipo_cuenta_descripcion,
              value: it.tipo_cuenta,
            })
          );
          (infoBancaria?.dias_pago ?? []).forEach((it) =>
            dias.push({ label: it.dia_pago_descripcion, value: it.dia_pago })
          );

          const dataPAC = {
            bancos: {
              label: labelBanco,
              answers: bancos,
              selected: "",
              placeholder: labelBanco,
              rules: [],
            },
            cuentas: {
              label: labelTipoCuenta,
              answers: cuentas,
              selected: "",
              placeholder: labelTipoCuenta,
              rules: [],
            },
            dias: {
              label: labelDiaPago,
              answers: dias,
              selected: "",
              placeholder: labelDiaPago,
              rules: [],
            },
          };
          commit("setPAC", dataPAC);
          resolve(result);
        })
        .catch((error) => {
          commit("setPAC", []);
          reject(error);
        });
    });
  },
  getPAT({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/medio-pago/PAT`,
        method: "GET",
      })
        .then((res) => {
          const dataPAT = {
            label: labelDiaPago,
            answers: [],
            selected: "",
            placeholder: labelDiaPago,
            rules: [],
          };

          res.data.data.dias_pago.map((it) => {
            dataPAT.answers.push({
              label: it.dia_pago_descripcion,
              value: it.dia_pago,
            });
          });
          resolve(res.data.data);
          commit("setPAT", dataPAT);
        })
        .catch((error) => {
          commit("setPAT", []);
          reject(error);
        });
    });
  },
  createLink({ commit }, data) {
    commit("setCreateLink", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/pago/contado`,
        method: "POST",
        data: {
          monto: data.monto,
        },
      })
        .then((res) => {
          resolve(res.data.data);
          commit("setCreateLink", false);
        })
        .catch((error) => {
          commit("setCreateLink", false);
          reject(error);
        });
    });
  },

  SEND_PAYMENT({ commit }, payload) {
    // Payload => data: Objeto del medio de pago seleccionado, id: Id de la propuesta
    const path = `/propuestas/${payload.id}/medio-pago`,
      formData = payload.data;
    return new Promise((resolve, reject) => {
      axios
        .put(path, formData)
        .then((response) => {
          commit("setSelectedPayment", response.data);
          resolve(response.data);
        })
        .catch(() =>
          reject(
            new Error("No se ha logrado guardar el medio de pago seleccionado")
          )
        );
    });
  },

  REQUEST_BANK_LIST({ commit }) {
    const path = `${URL_API_NEGOCIO}/bancos/`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((response) => {
          const result = response.data.data.result;
          commit("setBankList", result);
          resolve("ok");
        })
        .catch(() =>
          reject(
            new Error(
              "Ha ocurrido un error al intentar recuperar el listado de los bancos."
            )
          )
        );
    });
  },
  OpenShowModal({ commit }, data) {
    commit("setShowModal", true);

    commit("setItemShow", data.item);
  },

  CloseShowModal({ commit }) {
    commit("setShowModal", false);
    commit("setItemShow", null);
  },
  OpenEmailModal({ commit }) {
    commit("setShowEmailModal", true);
  },
  CloseEmailModal({ commit }) {
    commit("setShowEmailModal", false);
  },
};

export default actions;

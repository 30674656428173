import axios from "axios";

const state = {
  Ofertas: [],
  propuesta: null,
  cotizacionStatus: null,
  proposal_data: null,
  DataCompared: JSON.parse(sessionStorage.getItem("data_compared")) || [],
  OfertaCompared: JSON.parse(sessionStorage.getItem("oferta_compared")) || [],
  UserQuotation: JSON.parse(sessionStorage.getItem("user_quot")) || {},
  dealerStructure: [],
  availableYears: [],
  carUseState: null,
};

const getters = {
  Ofertas: (state) => state.Ofertas,
  propuesta: (state) => state.propuesta,
  cotizacionStatus: (state) => state.cotizacionStatus,
  Proposal: (state) => state.proposal_data,
  DataToCompared: (state) => state.DataCompared,
  OfertaCompared: (state) => state.OfertaCompared,
  UserQuot: (state) => state.UserQuotation,
  DealerStructure: (state) => state.dealerStructure,
  yearsList: (state) => state.availableYears,
};
export const mutations = {
  Ofertas(state, ofertas) {
    state.Ofertas = ofertas;
  },

  SET_PROPUESTA(state, propuesta) {
    state.propuesta = propuesta;
  },
  SET_PROPOSAL_DATA(state, proposal) {
    state.proposal_data = proposal;
  },

  GET_COTIZA_ERR(state, newStatus) {
    state.cotizacionStatus = newStatus;
  },

  SET_COMPARISON_DATA(state, data) {
    state.DataCompared = data;
    sessionStorage.setItem("data_compared", JSON.stringify(data));
  },
  SET_OFERTA_DATA(state, data) {
    state.OfertaCompared = data;
    sessionStorage.setItem("oferta_compared", JSON.stringify(data));
  },
  SET_USER_QUOT(state, data) {
    state.UserQuotation = data;
    sessionStorage.setItem("user_quot", JSON.stringify(data));
  },

  setDealerStructure(state, data) {
    state.dealerStructure = data;
  },

  setAvailableYears(state, payload) {
    state.availableYears = payload;
  },

  setCarUseState(state, payload) {
    state.carUseState = payload;
  },
};

export const actions = {
  GetOferta({ commit }, ofertas) {
    commit("Ofertas", ofertas);
  },

  REQUEST_DEALER_STRUCTURE({ commit }) {
    const url = `/concesionarios-externos`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          const result = response.data.data.concesionarios;
          commit("setDealerStructure", result);
          resolve(result);
        })
        .catch(() =>
          reject(
            new Error(
              "No se ha logrado recuperar los concesionario y su estructura"
            )
          )
        );
    });
  },

  REQUEST_QUOTATION_YEARS({ commit }) {
    const path = `/anios`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((response) => {
          const result = response.data.data.result;
          commit("setAvailableYears", result);
          resolve(result);
        })
        .catch(() =>
          reject(
            new Error("No se ha logrado recuperar los años para la cotización")
          )
        );
    });
  },

  REQUEST_CHANNEL_VEHICLE_USE({ commit }) {
    const url = `/vehiculos/canal-habilita-nuevo-usado`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((response) => {
          const result = response.data.data;
          commit("setCarUseState", result);
          resolve(result);
        })
        .catch(() =>
          reject("No se ha logrado recuperar los datos de uso del vehiculo.")
        );
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};

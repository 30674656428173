import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import AuthLayout from "../layouts/Auth.vue";
import Dashboard from "../layouts/Dashboard.vue";
import ErrorLayout from "../layouts/Error.vue";

// Pages
import DashboardPages from "./dashboard";
import AuthPages from "./auth";

Vue.use(VueRouter);

const routes = [
  // GUEST
  {
    path: "/auth",
    component: AuthLayout,
    children: AuthPages,
  },

  // REQUIERE AUTENTICACION
  {
    path: "/",
    component: Dashboard,
    children: DashboardPages,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/error",
    name: "Error",
    component: ErrorLayout,
  },
  {
    path: "*",
    component: ErrorLayout,
    props: () => ({ codigo: 4 }),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: "Login" });
  } else if (to.path === "/") {
    next({ name: "dashboard" });
  }
  next();
});

export default router;

<template>
  <div class="body" :style="styles" id="main-container">
    <!-- Menu lateral izquierdo -->
    <left-navbar-menu />
    <!-- Barra superior -->
    <top-navbar :NotifyUser="NewNotification"></top-navbar>
    <!-- Barra de carrito -->
    <v-slide-y-transition>
      <buy-navbar v-if="$route.name == 'Contract'"></buy-navbar>
    </v-slide-y-transition>
    <!-- Contenido -->
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
  </div>
</template>

<script>
import SideBar from "@/components/Sidebar/TheSideBar";
import Navigation from "@/components/NavigationBar/TheNavigationBar";
import BuyNavbar from "@/components/NavigationBar/BuyNavigationBar.vue";

import { darkorlightStyle } from "@/store/resources/calc.js";
export default {
  name: "DashboardLayout",
  components: {
    "left-navbar-menu": SideBar,
    "top-navbar": Navigation,
    BuyNavbar,
  },

  created() {
    /* if (!this.channels.length > 0) {
      this.$router.push({ name: "Error", query: { codigo: 11 } });
    } */
  },

  computed: {
    channels() {
      return this.$store.getters.getChannels;
    },
    NewNotification() {
      return this.$store.getters.NotifyUser;
    },
    styles() {
      let customStyles = this.$store.getters.getCustomStyles;
      return {
        "--bg-color":
          customStyles.background_color !== ""
            ? customStyles.background_color
            : "#F4F7FC",
        "--btn-color":
          customStyles.boton_color !== ""
            ? customStyles.boton_color
            : "#1482CC",
        "--btn-color-text":
          customStyles.boton_color !== ""
            ? darkorlightStyle(customStyles.boton_color)
            : "#F5F5F5",
        "--navbar-color":
          customStyles.navbar_color !== ""
            ? customStyles.navbar_color
            : "#F5F5F5",
        "--menu-color":
          customStyles.menu_color !== "" ? customStyles.menu_color : "#F5F5F5",
        "--menu-icon-color":
          customStyles.menu_color !== ""
            ? darkorlightStyle(customStyles.menu_color)
            : "#F5F5F5",
        "--box-color":
          customStyles.box_color !== "" ? customStyles.box_color : "#FFFFFF",
        "--text-color":
          customStyles.texto_color !== ""
            ? customStyles.texto_color
            : "#757575",
        "--title-color":
          customStyles.titulo_color !== ""
            ? customStyles.titulo_color
            : "#212128",
        "--icon-color":
          customStyles.icono_color !== ""
            ? customStyles.icono_color
            : "#FFFFFF",
      };
    },
  },
};
</script>

<style lang="scss">
$text-color: var(--text-color);
$title-color: var(--title-color);
$icon-color: var(--icon-color);
$background-color: var(--bg-color);
$navbar-color: var(--navbar-color);
$menu-color: var(--menu-color);
$menu-icon-color: var(--menu-icon-color);
$box-color: var(--box-color);
$box-light-color: #fff;
$btn-color: var(--btn-color);
$btn-text-color: var(--btn-color-text);
$warning-color: #ffcc00;
$info-color: #0077c8;
$error-color: #b3261e;
$success-color: #06844d;

.v-application {
  ::-webkit-scrollbar {
    width: 8px;
    display: initial !important;
  }
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.25);
  }
  ::-webkit-scrollbar-thumb {
    background-color: $btn-color;
    transition: background-color 1s;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-button {
    background-color: rgba(0, 0, 0, 0);
  }
  ::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0);
  }
}
// Contenido
.body {
  // Color general de los textos
  color: $text-color !important;
  font-family: "SansProRegular" !important;
  // Parrafos
  p {
    color: $text-color !important;
    margin-bottom: 0 !important;
    padding: 0;
    font-size: 14px;
  }
  // Titulos
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .title-color {
    color: $title-color !important;
    letter-spacing: initial !important;
  }

  .primary-bg {
    background-color: $btn-color !important;
  }
  .primary-border {
    border-color: $btn-color !important;
  }

  .warning-text {
    color: $warning-color !important;
  }
  .info-text {
    color: $info-color !important;
  }
  .error-text {
    color: $error-color !important;
  }
  .success-text {
    color: $success-color !important;
  }
  span.material-symbols-rounded {
    color: $icon-color !important;
    &.icon-success {
      color: $success-color !important;
    }
    &.icon-info {
      color: $info-color !important;
    }
    &.icon-error {
      color: $error-color !important;
    }
    &.icon-warning {
      color: $warning-color !important;
    }
    &.primary--text {
      color: $title-color !important;
    }
    &.text-white {
      color: white !important;
    }
  }
  // Iconos (General)
  .v-icon.m-icon,
  span.material-symbols-rounded {
    color: $icon-color;
    &.warning-text {
      color: $warning-color !important;
    }
    &.info-text {
      color: $info-color !important;
    }
    &.error-text {
      color: $error-color !important;
    }
    &.success-text {
      color: $success-color !important;
    }
    &.primary--text {
      color: $title-color !important;
    }
  }
  .v-alert__border {
    &.warning {
      background-color: $warning-color !important;
      border-color: $warning-color !important;
    }
    &.info {
      background-color: $info-color !important;
      border-color: $info-color !important;
    }
    &.error {
      background-color: $error-color !important;
      border-color: $error-color !important;
    }
    &.success {
      background-color: $success-color !important;
      border-color: $success-color !important;
    }
  }

  :root {
    --color: #ccc;
  }
  // Comtenedor principal
  .v-main {
    min-height: 100vh;
    background: $background-color !important;
  }
  .main-background-color {
    background: $background-color !important;
  }

  .title-color-background {
    background-color: $title-color !important ;
  }

  .text-white {
    color: #fff !important;
  }
  // Barra de navegacion superior
  .v-toolbar {
    background-color: $navbar-color !important;
    &.checkout-bar {
      background-color: $box-light-color !important;
    }
  }
  // Barra de navegacion lateral
  .v-navigation-drawer {
    background-color: $menu-color !important;
    .version {
      color: $menu-icon-color !important;
    }
    .v-icon {
      color: $menu-icon-color !important;
    }
    .v-list {
      .v-list-item--active {
        background-color: #fff;
        border-radius: inherit;
        .v-list-item__icon span,
        .v-list-item__title {
          color: $title-color !important;
        }
      }
      .v-list-item__icon span,
      .v-list-item__title {
        color: #fff !important;
      }
    }
    .tab {
      &.router-link-active,
      &.v-btn--active {
        background-color: white;
        border: solid 1px $menu-icon-color !important;
        color: $menu-color !important;
        .v-icon {
          color: $menu-color !important;
        }
      }
    }
  }
  // Listas
  .v-list-item {
    .v-list-item__title {
      color: $title-color !important;
    }
  }
  // Modal o dialogos
  .v-dialog {
    .v-card.bordered {
      .card-content {
        inset: 6px;
      }
    }
  }
  // Tablas
  .v-data-table {
    border-radius: 16px !important;
  }
  // Contenedores, tarjetas o cajas
  .v-card {
    //border-left: ridge 6px  !important;
    border-radius: 0.75em !important;
    position: relative;
    transition: height 0.6s;
    &.bordered {
      background: linear-gradient(
        45deg,
        transparent 5%,
        $box-color 30%,
        transparent 99%
      );

      .card-content {
        background-color: white;
        border-radius: inherit !important;
        inset: 2px;
        position: absolute;
      }

      &:hover:before {
        opacity: 1;
      }

      &:before {
        border-radius: inherit;
        opacity: 0;
        content: "";
        inset: 0px;
        transition: opacity 400ms;
        background: linear-gradient(
          145deg,
          white 5%,
          $box-color 50%,
          white 99%
        );
        position: absolute;
        z-index: 0;
      }
    }

    &.offer-card {
      position: relative;
      overflow: hidden;
      .offer-badge {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateY(70%) translateX(20%) rotate(35deg);
        background-color: $icon-color; /* Color de la banda */
        color: white; /* Color del texto */
        padding: 2px 26px; /* Espaciado interior */
        font-weight: bold; /* Estilo del texto */
        font-size: 12px; /* Tamaño del texto */
        z-index: 1; /* Asegúrate de que esté en la parte superior */
      }
    }

    &.invisible {
      border: none !important;
      background: inherit !important;
      &:before,
      &:hover:before {
        background: inherit !important;
      }
    }

    &.br-6 {
      border-radius: 0.25em !important;
    }
    &.br-8 {
      border-radius: 0.45em !important;
    }
    &.br-12 {
      border-radius: 0.65em !important;
    }

    &.card-disabled {
      &::before {
        background-color: rgba($color: black, $alpha: 0.45) !important;
      }
    }
    //&.light {
    //  background-color: $box-light-color !important;
    //}

    &.v-card--link {
      &:before {
        background-color: rgba(0, 119, 200, 0.12) !important;
      }
    }

    // Elevations
    &.elevation-1 {
      box-shadow: 0 0px 0px -1px rgba(0, 0, 0, 0.14),
        0 0px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 0px 0 rgba(0, 0, 0, 0.14) !important;
    }

    &.with-border {
      border: 2px solid $btn-color !important;
    }

    &.selected {
      border: 1px solid $icon-color !important;
      transition: border 0.4s, color 0.4s;
      .v-card__text {
        background-color: rgba(0, 119, 200, 0.12) !important;
        transition: all ease 0.4s;
      }
    }
    &.hoverable {
      transition: border 0.4s;
      cursor: pointer !important;
      &:hover {
        border: 1px solid $icon-color !important;
        transition: border 0.4s, color 0.4s;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        .v-icon {
          color: $icon-color !important;
        }
        .v-card__text {
          background-color: rgba(0, 119, 200, 0.12) !important;
          transition: all ease 0.4s;
        }
      }
    }
    .v-icon {
      color: $title-color;
    }
    // Titulos de las tarjetas o cajas
    .v-card__title {
      color: $title-color !important;
      // Titulos de tarjeta con fondo
      &.m-header {
        background-color: $btn-color !important;
        color: $btn-text-color !important;
      }
    }
    // Elemento de carga de la tarjeta o card loader
    .v-card__progress {
      .v-progress-linear__background {
        background-color: $btn-text-color !important;
        border-color: $btn-text-color !important;
      }
      .v-progress-linear__indeterminate {
        &.primary {
          background-color: $btn-color !important;
          border-color: $btn-color !important;
        }
      }
    }
    // Lista y tabuladores de la tarjeta
    .v-list,
    .v-tabs-items {
      background-color: transparent !important;
    }
    .v-list.v-select-list {
      background: #fff !important;
    }
    .v-tabs-bar {
      background-color: rgba(255, 255, 255, 0.6) !important;
      color: $btn-color !important;
      .v-tab {
        color: $text-color !important;
      }
    }
    // Campos de textos o Inputs de las tarjetas
    .v-input {
      background-color: rgba($color: #000000, $alpha: 0) !important;
      &.v-input--radio-group,
      &.v-input__slider,
      &.v-input--checkbox {
        .v-input__slot {
          background-color: transparent;
        }
        label {
          color: $text-color !important;
        }
      }
      .v-input__slot {
        background-color: white;
      }
      .v-text-field__details {
        background-color: transparent !important;
      }
      .v-input--selection-controls__input {
        .v-icon {
          color: #cac4d0 !important;
          caret-color: $btn-color !important;
        }
      }
      &.v-input--is-disabled {
        .v-input__control {
          .v-input__slot {
            // background-color: red !important;
            color: rgba(0, 0, 0, 0.38) !important;
            background-color: rgba(202, 196, 208, 0.1) !important;
            border-color: #cac4d0 !important;
          }
        }
      }
      &.v-input--is-label-active {
        .v-input--selection-controls__input {
          .v-icon {
            color: $btn-color !important;
            caret-color: $btn-color !important;
          }
        }
      }

      // Colores para los radio btns
      .v-radio {
        &.v-radio--is-disabled {
          .v-input--selection-controls__input {
            .v-icon {
              // background-color: red !important;
              color: rgba(0, 0, 0, 0.38) !important;
              background-color: rgba(202, 196, 208, 0.1) !important;
              border-color: #cac4d0 !important;
            }
          }
          .v-label {
            color: rgba(0, 0, 0, 0.38) !important;
          }
        }
      }
    }
    // Paneles de las tarjetas
    .v-expansion-panel {
      background-color: transparent !important;
      .v-icon {
        color: $icon-color !important;
      }

      .v-expansion-panel-header--active::before {
        opacity: 0 !important;
      }
    }
  }
  // Numero flotante
  .v-badge {
    .v-badge__badge {
      &.primary {
        background-color: $btn-color !important;
        border-color: $btn-color !important;
        color: $btn-text-color !important;
      }
    }
  }
  // TAGS
  .v-chip {
    &.primary {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
      color: $btn-text-color !important;
      transition: box-shadow 0.6s;
      &.v-chip--outlined {
        color: $btn-color !important;
        background-color: $btn-color !important;
        &.v-chip--active {
          transition: box-shadow 0.6s;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
          .v-chip__content {
            color: $btn-color !important;
          }
        }
      }
    }
    &.primary--text {
      .v-chip__content {
        color: $btn-color !important;
      }
    }
  }
  .cursor-pointer {
    cursor: pointer !important;
  }
  // Botones
  .v-btn {
    font-weight: 600;
    transition: color 0.4s, background-color 0.4s ease-in-out, box-shadow 0.4s;
    // Boton normal
    &.primary {
      background-color: $btn-color !important;
      border-color: $btn-color !important;
      color: $btn-text-color !important;
      .v-icon {
        color: $btn-text-color !important;
      }

      &:hover {
        background-color: $btn-text-color !important;
        box-shadow: inset 0 0 60px $btn-color;
      }
    }
    .v-btn__content {
      letter-spacing: 0.1px !important;
    }
    // Boton con solo el texto
    &.primary--text {
      color: $btn-color !important;
      caret-color: $btn-color !important;
      .v-icon {
        color: $btn-color !important;
      }
      .v-btn__content {
        color: $btn-color !important;
      }
    }
    &.v-btn--disabled {
      cursor: not-allowed;
      .v-btn__content {
        opacity: 0.3 !important;
        color: #fff !important;
      }
    }
  }
  // Campos de texto o INPUTS
  .v-text-field:hover {
    fieldset {
      border: 1px solid $btn-color !important;
    }
  }
  .v-input:hover {
    .v-input__control {
      .v-label {
        color: $btn-color !important;
      }
    }
  }
  // Elemento de carga o loader
  .v-progress-circular {
    // Carga circular
    &.primary--text {
      color: $btn-color !important;
      caret-color: $btn-color !important;
    }
  }
  .v-progress-linear {
    // Carga Linear
    .v-progress-linear__indeterminate {
      &.primary {
        background-color: $btn-color !important;
        border-color: $btn-color !important;
      }
    }
  }
  // Tablas
  .v-data-table {
    // Table Elevations
    &.elevation-1 {
      box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.14),
        0 1px 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14) !important;
    }
    .v-data-table__wrapper {
      table {
        .v-data-table-header {
          tr {
            th {
              color: $title-color !important;
            }
          }
        }
        .v-data-table__progress {
          th {
            height: 0px !important;
          }
        }
        tbody {
          td {
            color: $text-color !important;
          }
        }
      }
    }
  }
  // Borde inferior de algunos elementos
  .global-borderStyle {
    border-bottom-color: $btn-color !important;
  }
  //Elementos flotantes
  .v-menu__content {
    &.fixed {
      position: fixed;
    }
  }
  .v-list.sidebar-submenu {
    background-color: $box-color !important;
    .v-list-item__icon i,
    .v-list-item__title {
      color: $title-color !important;
    }
  }

  .v-alert {
    &.info-alert {
      background-color: rgba(0, 119, 200, 0.1) !important;
    }
    &.warning-alert {
      background-color: rgba(255, 204, 0, 0.1) !important;
    }
    &.success-alert {
      background-color: rgba(6, 132, 77, 0.1) !important;
    }
    &.error-alert {
      background-color: rgba(179, 38, 30, 0.1) !important;
    }
  }
}
</style>

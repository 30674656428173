<template>
  <v-app-bar app>
    <v-badge
      :value="Notify.length"
      :content="Notify.length"
      offset-x="30"
      offset-y="15"
      v-if="this.$store.state.Authentication.perfil === 'admin'"
    >
      <v-btn text @click="actionBtn">
        <v-icon class="m-icon" color="black">mdi-bell-outline</v-icon>
      </v-btn>
      <v-card
        v-if="Notifications"
        class="global-container"
        v-click-outside="onClickOutside"
      >
        <v-flex lg12 class="d-flex pb-3 pl-5 pr-3 mt-4">
          <h3 class="global-archtxt mt-2 mr-4">Notificaciones</h3>
          <v-spacer></v-spacer>
          <v-btn
            :loading="loadbtn"
            small
            text
            :color="btnColor"
            v-if="Notify.length >= 1"
            class="global-button text text-capitalize mt-1"
            @click="Read"
          >
            <i class="bi bi-check-circle mr-3" /> Marcar como leídas</v-btn
          >
        </v-flex>
        <v-card flat class="d-flex global-cardnotificaiton overflow-x-auto">
          <v-virtual-scroll
            v-if="Notify.length >= 1"
            :items="Notify"
            :item-height="95"
            height="220"
          >
            <template v-slot:default="{ item }" class="cardsnotifica">
              <CardNotification :Data="item" :actionBtn="ActionButton" />
            </template>
          </v-virtual-scroll>
        </v-card>
      </v-card>
    </v-badge>
    <div class="d-flex w-100 align-center">
      <div
        class="d-flex flex-row ml-5"
        :class="[
          showCart ? 'justify-start w-50' : 'justify-space-between w-100',
        ]"
      >
        <div class="d-flex align-center justify-center">
          <h3 v-if="showCart">{{ profile.nombre }}</h3>
          <h3 v-else>¡Hola {{ profile.nombre }}!</h3>

          <h5 class="ml-4" v-if="!showCart">
            Busca y cotiza lo mejor para tus clientes.
          </h5>
        </div>
        <div class="d-flex align-center justify-center">
          <h5 class="mr-3" :class="[showCart ? 'ml-3' : '']">
            Estás en la sucursal
          </h5>

          <v-menu
            rounded
            offset-y
            :disabled="currentRouteName !== 'dashboard'"
            max-height="350"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                text
                color="primary"
                class="text-capitalize"
                v-bind="attrs"
                v-on="on"
                :loading="loadCanal"
              >
                {{ canal?.description || "Canales" }}
                <v-icon v-if="currentRouteName == 'dashboard'" class="m-icon"
                  >mdi-chevron-down</v-icon
                >
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-for="(canal, i) in canales"
                :key="i"
                link
                @click="changeCanal(canal)"
              >
                <v-list-item-title
                  v-text="canal.canal_descripcion || ''"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-if="showCart" class="w-50 d-flex">
        <buy-navbar v-if="$route.name == 'Contract'"></buy-navbar>
      </div>
    </div>

    <!-- <v-avatar class="ml-8 global-avatar" size="48">
      <img alt="Avatar" :src="user?.foto_usuario ?? ''" />
    </v-avatar> -->
    <!-- <div class="ml-7 mr-7 global-username align-self-center">
      <h4>{{ user?.nombre ?? "" }}</h4>
      <p class="caption mb-0">{{ (user?.usuario ?? "") | rutFormat }}</p>
    </div> -->
  </v-app-bar>
</template>

<script>
import CardNotification from "@/components/Cards/NewUserCreated-card.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import BuyNavbar from "@/components/NavigationBar/BuyNavigationBar.vue";

export default {
  name: "BarraDeNavegacion",
  components: {
    CardNotification,
    BuyNavbar,
  },
  data: () => ({
    loadbtn: false,
    loadCanal: false,
    selectedConvenio: "inalco.mesos.cl",
    Notifications: false,
    Notify: [],
    arrayid: [],
    message: "",
    callback: () => {},
  }),

  methods: {
    ...mapMutations("Users", ["setConvenio"]),
    executeEvent() {
      this.cart.function();
    },
    changeConvenio(i) {
      this.setConvenio(i);
      this.$store.commit("SET_CANAL", "");
      this.$store.dispatch("GetCanales", {
        convenio: i,
        canal: null,
        sucursal: null,
      });
      this.selectedConvenio = i;

      this.$store.commit("SET_CONVENIO", this.selectedConvenio);
    },
    changeCanal(canal) {
      this.loadCanal = true;
      this.$store
        .dispatch("tokenRefresh", canal.id_canal)
        .then(() => {
          this.$store.dispatch("REQUEST_CHANNEL_INFO", canal);
        })
        .finally(() => (this.loadCanal = false));
    },
    ActionButton() {
      this.$router.push("/personas");

      this.$store.dispatch("NotificationBar");
      this.Notifications = !this.Notifications;
    },

    onClickOutside() {
      this.Notifications = false;
    },
    Read() {
      this.loadbtn = true;
      axios({
        url: "notificaciones",
        method: "PUT",
        data: {
          array_id: this.arrayid,
        },
      })
        .then((res) => {
          if (res) {
            this.Notifications = !this.Notifications;

            this.loadbtn = false;
            this.Notify = [];
          }
        })
        .catch((err) => {
          if (err) this.loadbtn = false;
        });
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    actionBtn() {
      this.Notifications = !this.Notifications;
    },
  },
  computed: {
    ...mapGetters("Users", ["getMainLogo", "getBotonColor", "getConvenio"]),
    user() {
      return this.$store.getters.user;
    },
    mainLogo() {
      return this.getMainLogo;
    },
    btnColor() {
      return this.getBotonColor;
    },
    convenio() {
      return this.getConvenio;
    },
    canal() {
      return this.$store.getters.getChannelSelected;
    },
    canales() {
      return this.$store.getters.getChannels.filter(
        (e) => e.id_canal !== (this.canal ? this.canal.id : "")
      );
    },
    sucursal() {
      return this.$store.getters.sucursal;
    },
    sucursales() {
      return this.$store.getters.sucursales;
    },
    currentRouteName() {
      return this.$route.name;
    },
    profile() {
      return this.$store.getters.user;
    },
    showCart() {
      const showCartRoutes = ["/contratacion"];
      return showCartRoutes.includes(this.$route.path);
    },
  },

  mounted() {
    if (this.$store.state.Authentication.perfil === "admin") {
      axios({
        url: "notificaciones",
        method: "GET",
      })
        .then((response) => {
          this.Notify = response.data.data.notificaciones;

          for (let i = 0; i < this.Notify.length; i++) {
            this.arrayid.push(this.Notify[i].id);
          }
        })
        .catch(() => {});
    }
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.v-card {
  max-width: none;
}
</style>

import {
  paymentStatusCodes,
  paymentMethodsCodes,
} from "@/store/resources/payments.js";

const mutations = {
  changeStatusQuotation(state, status) {
    state.creatingQuotation = status;
  },
  setErrorRetake(state, status) {
    state.errorRetake = status;
  },
  changeProposalStep(state, payload) {
    state.step = payload;
  },

  setRetaking(state, status) {
    state.retaking = status;
  },

  setOffersQuotation(state, offers) {
    state.offersQuotation = offers;
  },

  setQuotationState(state, status) {
    state.quotationState = status;
  },

  setCities(state, cities) {
    state.cities = cities;
    sessionStorage.setItem("cities_one", JSON.stringify(cities));
  },

  setCitiesTwo(state, cities) {
    state.cities = cities;
    sessionStorage.setItem("cities_two", JSON.stringify(cities));
  },

  setPayment(state, methods) {
    const mpayElementCond1 = methods.find((e) => {
      const isValid =
        // Mpay1 con codigo de estado pendiente
        (e.codigo === paymentMethodsCodes[5] &&
          (e.codigo_estado === paymentStatusCodes[1] ||
            e.estado === paymentStatusCodes[1])) ||
        // o...
        // Mpay2 con codigo de estado pendiente o aprobado
        ((e.codigo === paymentMethodsCodes[6] ||
          e.codigo === paymentMethodsCodes[7]) &&
          (e.codigo_estado === paymentStatusCodes[1] ||
            e.estado === paymentStatusCodes[1] ||
            e.codigo_estado === paymentStatusCodes[2] ||
            e.estado === paymentStatusCodes[2]));
      return isValid;
    });

    // Bloqueo de medios de pago por MPAY
    if (mpayElementCond1) {
      state.blocked_by_payment_method = true;
    } else {
      state.blocked_by_payment_method = false;
    }

    // Verificacion de si hay algun medio de pago mpay2 en estado rechazado;
    const mpayElementCond2 = methods.find((e) => {
      return (
        (e.codigo === paymentMethodsCodes[6] ||
          e.codigo === paymentMethodsCodes[7]) &&
        (e.codigo_estado === paymentStatusCodes[3] ||
          e.estado === paymentStatusCodes[3])
      );
    });
    if (mpayElementCond2) {
      state.mpay2Blocked = true;
    }

    state.payment_methods = [];
    state.payment_methods = methods.map((element) => element);
  },

  setPaymentStatus(state, status) {
    state.payment_methods.forEach((element) => {
      if (element.codigo === paymentMethodsCodes[5]) {
        element.codigo_estado = status;
        if (
          status === paymentStatusCodes[2] ||
          status === paymentStatusCodes[3]
        ) {
          state.blocked_by_payment_method = false;
        }
      }
    });
  },

  setMPayState(state, payload) {
    sessionStorage.setItem("mpay", JSON.stringify(payload));
    state.mpay = payload;
  },

  setMPay2State(state, payload) {
    sessionStorage.setItem("mpay2", JSON.stringify(payload));
    state.mpay2 = payload;
  },

  setBuyCart(state) {
    state.buy_cart = !state.buy_cart;

    sessionStorage.setItem("showBuyCart", state.buy_cart);
  },

  setShoppingCart(state, resume) {
    sessionStorage.setItem("buy_cart_resume", JSON.stringify(resume));
    state.buy_cart_resume = resume;
  },

  setTarifa(state, payload) {
    sessionStorage.setItem("tarifa_selected", JSON.stringify(payload));
    state.tarifa = payload;
  },

  setProposalEmail(state, payload) {
    if (state.proposal_data?.cotizacion) {
      state.proposal_data.cotizacion.correo_cliente = payload;
    }
  },

  SetRegularDocuments: (state, payload) => (state.documents.regular = payload),
  SetPendingDocuments: (state, payload) => (state.documents.pending = payload),
  SetToppings: (state, payload) => (state.documents.toppings = payload),

  setCreating(state, status) {
    state.creating = status;
  },
  setError(state, status) {
    state.error_creating = status;
  },

  setBeneficts(state, data) {
    sessionStorage.setItem("beneficts", JSON.stringify(data));
    state.beneficts = data;
  },

  setProposalGood(state, data) {
    state.proposal_good = data;
  },

  // Actualizar los datos del bien asegurado en la propuesta
  setGoodies(state, payload) {
    state.proposal.datos_bien = payload;
  },

  setInsured(state, data) {
    state.proposal.asegurado = data;
  },

  setCompanyLogo(state, payload) {
    state.companyLogo[payload.name] = payload.value;
  },

  setInsurableInterest(state, payload) {
    state.InsurableInterestList = payload;
  },
  setTypeOfDocuments(state, payload) {
    state.documentType = payload;
  },
  setColors(state, payload) {
    state.colors = payload;
  },
  setProposal(state, payload) {
    sessionStorage.setItem("proposal_data", JSON.stringify(payload));
    state.proposal = payload;
  },
  setDataProposalStep(state, payload) {
    state.dataProposalStep = payload;
  },
};

export default mutations;

import axios from "axios";
const actions = {
  getResume(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `cotizaciones/${data}/resumen`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDiscounts(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos`,
        method: "GET",
        params: data,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getCargosMotivos(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos/cargos_motivos`,
        method: "GET",
        params: data,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getFile(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos/exportar`,
        method: "GET",
        responseType: "blob",
        params: data,
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createDiscount(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos`,
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  calculateDiscount(context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos/calcular`,
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  approveDiscount(context, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos/${id}/aprobacion`,
        method: "PUT",
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  rejectDiscount(context, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `descuentos/${params.id}/rechazo`,
        method: "PUT",
        data: { motivo: params.motivo },
      })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default actions;

export const errorLayoutData = [
  {
    id: 1,
    imgSrc: "",
    title: "Ops! Ha ocurrido un error",
    description:
      "Un error inesperado ha sucedido, contacte con el administrador del sistema.",
  },
  {
    id: 4,
    imgSrc: "",
    title: "Página no encontrada",
    description: "La página solicitada no se ha encontrado en los registros",
  },
  {
    id: 11,
    imgSrc: "",
    title: "No hay canales para mostrar",
    description: "Este usuario no posee canales configurados.",
  },
];

export default {
  errorLayoutData,
};

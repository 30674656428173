import Vue from "vue";
import dateformat from "dateformat";
import moment from "moment";

import { VueMaskFilter } from "v-mask";

dateformat.i18n = {
  dayNames: [
    "Dom",
    "Lun",
    "Mar",
    "Mie",
    "Jue",
    "Vie",
    "Sab",
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
  monthNames: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

Vue.filter("formatDate", function (value, type) {
  let dateFormat;
  if (value) {
    if (type !== "doc") {
      dateFormat = moment(new Date(value)).format("DD/MM/YYYY");
    } else {
      dateFormat = dateformat(new Date(value), "d mmmm yyyy h:MM:ss TT");
    }
  }
  return dateFormat;
});

Vue.filter("formatDatePay", function (value) {
  let dateFormatPay;
  if (value) {
    dateFormatPay = dateformat(new Date(value), "d mmmm yyyy");
  }
  return dateFormatPay;
});

const moneyFormat = (val) => {
  const formatClp = Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    // maximumFractionDigits: 2,
  });

  return formatClp.format(val);
};

Vue.filter("moneyFormat", moneyFormat);

Vue.filter("UFPrice", function (value) {
  let v = "";
  if (typeof value == "string") {
    v = value.replace(".", ",");
  } else {
    const t = value > 0 ? value.toFixed(2) : value;
    v = t.toString().replace(".", ",");
  }
  return `UF ${v}`;
});

Vue.filter("numberFormat", function (numero) {
  let numeroFormateado = 0;
  if (numero) {
    const [parteEntera, parteDecimal] = numero.toFixed(2).split(".");
    numeroFormateado =
      parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parteDecimal && parteDecimal !== "00" ? "," + parteDecimal : "");
  }
  return numeroFormateado;
});

Vue.filter("formatDateDoc", function (value) {
  let formatDateDoc;
  if (value) {
    formatDateDoc = dateformat(new Date(value), "dd mmmm yyyy");
  }
  return formatDateDoc;
});

Vue.filter("formatBytes", function (bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
});

Vue.filter("rutFormat", function (rut) {
  let rutFormat;
  if (rut) {
    rutFormat = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
    for (let i = 4; i < rut.length; i += 3) {
      rutFormat = `${rut.slice(-3 - i, -i)}.${rutFormat}`;
    }
  }
  return rutFormat;
});

Vue.filter("VMask", VueMaskFilter);

import axios from "axios";
import { dateToNumber } from "@/store/resources/calc.js";
import { companyLogoUrl, defaultLogoPropName } from "@/services/file.js";

const URL_API_NEGOCIO = process.env.VUE_APP_BASE_URL_FUNCIONAL_NEGOCIO;

export const actions = {
  //validar si un usuario existe
  findUser: async function ({ commit }, payload) {
    // Payload => .rut del Cliente
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${payload.rut}`,
        method: "GET",
      })
        .then((res) => {
          const client = res.data.data.cliente;
          resolve(client?.cliente);
          commit("setSearchingUser", false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // Establecer la informacion del cliente
  setClient: ({ commit }, rutNumber) => {
    // Payload => rut del Cliente
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${rutNumber}`,
        method: "GET",
      })
        .then((res) => {
          const client = res.data?.data?.cliente || null,
            tipoPersona = client?.tipo_persona ?? "NAT",
            tipoCliente =
              tipoPersona === "NAT" ? client?.cliente : client.contacto;

          const result = {
            ...tipoCliente,
            tipo_persona: tipoPersona,
            razon_social: client?.cliente?.razon_social ?? "",
            comuna: client?.comuna ?? undefined,
            comuna_descripcion: client?.comuna_descripcion ?? undefined,
          };
          commit("setDataUser", client);
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Crear usuarios
  createUser(context, data = null) {
    const url = "clientes";
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "POST",
        data,
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject({
            message: "Ha ocurrido un error y no se ha logrado crear el cliente",
            error: err,
          });
        });
    });
  },
  // usuario natural
  createUserNatural: function ({ dispatch }, data) {
    return dispatch("createUser", {
      tipo_persona: "NAT",
      comuna: data.comuna?.value ?? "",
      comuna_descripcion: data.comuna?.label ?? "",
      cliente: {
        nombre: data.nombre,
        tipo_documento: "RUT",
        numero_documento: data.numero_documento,
        primer_apellido: data.primer_apellido,
        segundo_apellido: data.segundo_apellido,
        razon_social: "",
        fecha_nacimiento: dateToNumber(
          data.fecha_nacimiento.split("/").join("") || ""
        ),
        genero: data.genero || "M",
        datos_contacto: {
          email: data.email,
          telefono: data.telefono,
          otro: data.otro,
        },
      },
    });
  },

  //crear usuario juridico
  createUserJuridico: function ({ dispatch }, data) {
    return dispatch("createUser", {
      tipo_persona: "JUR",
      comuna: data.comuna?.value ?? "",
      comuna_descripcion: data.comuna?.label ?? "",
      cliente: {
        razon_social: data.razon_social,
        tipo_documento: "RUT",
        numero_documento: data.numero_documento,
      },
      contacto: {
        tipo_documento: "RUT",
        numero_documento: data.rut_contacto,
        nombre: data.nombre,
        primer_apellido: data.primer_apellido,
        segundo_apellido: data.segundo_apellido,
        fecha_nacimiento: dateToNumber(
          data.fecha_nacimiento.split("/").join("") || ""
        ),
        genero: data.genero || "M",
        datos_contacto: {
          email: data.email,
          telefono: data.telefono,
          otro: data.otro,
        },
      },
    });
  },

  // editar usuario natural
  editUserNatural: async function (context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data.numero_documento}`,
        method: "PUT",

        data: {
          tipo_persona: "NAT",
          comuna: data.comuna?.value ?? "",
          comuna_descripcion: data.comuna?.label ?? "",
          cliente: {
            nombre: data.nombre,

            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            razon_social: "",
            genero: data.genero,
            fecha_nacimiento: data.fecha_nacimiento.split("/").join(""),
            datos_contacto: {
              email: data.email,
              telefono: data.telefono,
              otro: data.otro,
            },
          },
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //editar usuario juridico
  editUserJuridico: async function (context, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data.numero_documento}`,
        method: "PUT",

        data: {
          tipo_persona: "JUR",
          comuna: data.comuna?.value ?? "",
          comuna_descripcion: data.comuna?.label ?? "",
          cliente: {
            razon_social: data.razon_social,
          },
          contacto: {
            tipo_documento: "RUT",
            numero_documento: data.numero_documento,
            nombre: data.nombre,
            primer_apellido: data.primer_apellido,
            segundo_apellido: data.segundo_apellido,
            genero: data.genero,
            fecha_nacimiento: data.fecha_nacimiento.split("/").join(""),
            datos_contacto: {
              email: data.email,
              telefono: data.telefono,
              otro: data.otro,
            },
          },
        },
      })
        .then((response) => {
          resolve({
            message: "¡Se ha actualizado el cliente correctamente!",
            result: response.data.data,
          });
        })
        .catch((err) => {
          reject({
            message: "Ha ocurrido un error al intentar actualizar un cliente.",
            error: err,
          });
        });
    });
  },
  findOffersUser: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/ofertas`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data);
          commit("setOffers", res.data.data.ofertas);
        })
        .catch((err) => {
          commit("setOffers", []);
          reject(err);
        });
    });
  },

  findProductsUser: function ({ commit }, data) {
    const params = [];
    if (data?.nroProp) {
      params.push(`propuesta=${data?.nroProp ?? ""}`);
    }
    const path = `clientes/RUT/${data?.nroDoc ?? ""}/productos${
      params.length > 0 ? "?" + params.join("&") : ""
    }`;
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
      })
        .then(async (res) => {
          const result = res.data?.data?.productos.map((it) => {
            it.canceling = false;
            (it.documentos ?? [])?.forEach((doc) => {
              doc.fecha_creacion_doc = doc.fecha_creacion;
            });
            it[defaultLogoPropName] = it[companyLogoUrl];
            return it;
          });
          commit("setProducts", result);
          resolve(result);
        })
        .catch((err) => {
          commit("setProducts", []);
          reject(err);
        });
    });
  },
  findQuotations: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/cotizaciones?codigo_estado=PEN`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data);
          commit("setQuotations", res.data.data.cotizaciones);
        })
        .catch((err) => {
          reject(err);
          commit("setQuotations", []);
        });
    });
  },
  findAllQuotations: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/cotizaciones`,
        method: "GET",
      })
        .then((res) => {
          res.data.data.cotizaciones.map((it, index) => {
            it.plazo_retoma = [];

            it.tarifas.ofertas.map((pr) => {
              it.plazo_retoma.push({
                plazo_retoma: pr.plazo_retoma,
                plan: pr.plan,
                deducible: pr.deducible,
                compania: pr.compania_descripcion,
                indice: index,
              });
            });
          });

          resolve(res.data.data.cotizaciones);
          commit("setQuotationsUser", res.data.data.cotizaciones);
        })
        .catch((err) => {
          reject(err);
          commit("setQuotationsUser", []);
        });
    });
  },
  findDocuments: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/documentos?estado=PEN`,
        method: "GET",
      })
        .then((res) => {
          commit("setDocuments", res.data.data.documentos);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("setDocuments", []);
          reject(err);
        });
    });
  },
  findAllDocuments: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/documentos`,
        method: "GET",
      })
        .then((res) => {
          commit("setDocumentsUser", res.data.data.propuestas);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("setDocumentsUser", []);
          reject(err);
        });
    });
  },
  findCategories: async function ({ commit }) {
    const path = `${URL_API_NEGOCIO}/ramos/all-services`;
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
      })
        .then((res) => {
          commit("setCategories", res.data.data.result);
          resolve("ok");
        })
        .catch(() => {
          commit("setCategories", []);
          reject(new Error("No se ha logrado recuperar las categorias."));
        });
    });
  },

  findProductDetail: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `clientes/RUT/${data}/productos/ABC123`,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data;
          commit("setProductDetail", result.producto);
          commit("setPlanPago", result.producto.medios_pago.plan_pago);
          resolve(result.producto);
        })
        .catch((err) => {
          commit("setProductDetail", {});

          reject(err);
        });
    });
  },
  findBrands: async function ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `marcas`,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data;
          commit("setBrands", result.Marcas);
          resolve(result.Marcas);
        })
        .catch((err) => {
          commit("setBrands", []);

          reject(err);
        });
    });
  },
  findBrandsType: async function ({ commit }, payload) {
    // Payload => Id tipo vehiculo
    const path = `marcas/tipo_vehiculo/${
      payload.tipo_vehiculo ? payload.tipo_vehiculo : payload
    }`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          const result = res.data.data;
          commit("setBrands", result.Marcas);
          resolve(result.Marcas);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  findModels: async function ({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `modelos/tipo_vehiculo/${data.tipo_vehiculo}/marcas/${data.marca}`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data.Modelos);
          commit("setModels", res.data.data.Modelos);
        })
        .catch((err) => {
          commit("setModels", []);
          reject(err);
        });
    });
  },
  findProductCategories: async function ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: `productos`,
        method: "GET",
      })
        .then((res) => {
          resolve(res.data.data.producto);

          commit("setProductCategories", {});
          const arrayProducts = {
            label: "Tipo de producto",
            answers: [],
            selected: "",
            placeholder: "Tipo de producto",
            rules: [(value) => !!value || "Campo requerido."],
          };
          res.data.data.producto.map((it) => {
            arrayProducts.answers.push({
              label: it.producto_descripcion,
              value: it.producto,
            });
          });
          commit("setProductCategories", arrayProducts);
        })
        .catch((err) => {
          commit("setProductCategories", {});

          reject(err);
        });
    });
  },
};

export default actions;

export default [
  // Seccion del Detalle del cliente
  {
    path: "/cliente/:rut",
    name: "UserDetail",
    component: () => import("../views/Users/UserDetail.vue"),
    props: true,
  },
  // Paginas internas
  {
    path: "/cliente/:rut/datos",
    name: "NewEditUser",
    component: () => import("../views/Users/AddEditUser.vue"),
    props: true,
  },
  {
    path: "/cliente/:rut/nueva-cotizacion",
    name: "NewQuotation",
    component: () => import("../views/Users/Detalle/NewQuotation.vue"),
    props: true,
  },
];

export const paymentMethodsCodes = {
  1: "PAT",
  2: "PAC",
  3: "C",
  4: "CV",
  5: "MPAY",
  6: "MPAY2",
  7: "MPAY_DOS_PUNTO_CERO",
  8: "AVISO_COBRANZA",
  10: "DEFAULT",
};

export const paymentMethods = [
  {
    code: paymentMethodsCodes[1],
    color: "warning lighten-1",
    hoverColor: "warning lighten-2",
    icon: "mdi-credit-card",
    method: "Pago a través de tarjetas",
    title: "Tarjeta de crédito",
    dark: true,
  },
  {
    code: paymentMethodsCodes[2],
    color: "warning",
    hoverColor: "warning lighten-1",
    icon: "mdi-bank",
    method: "Pago a través de cuenta corriente",
    title: "Cuenta Bancaria",
    dark: false,
  },
  {
    code: paymentMethodsCodes[3],
    color: "success",
    hoverColor: "success lighten-1",
    icon: "mdi-cash",
    method: "Pago en efectivo",
    title: "",
    dark: true,
  },
  {
    code: paymentMethodsCodes[4],
    color: "primary lighten-1",
    hoverColor: "primary lighten-2",
    icon: "mdi-account-cash",
    method: "Pago con Cuenta Vista",
    title: "",
    dark: false,
  },
  {
    code: paymentMethodsCodes[5],
    color: "primary",
    hoverColor: "primary lighten-1",
    icon: "mdi-credit-card-chip",
    method: "Factura con el servicio MesosPay",
    title: "M-PAY",
    dark: true,
  },
  {
    code: paymentMethodsCodes[6],
    color: "primary",
    hoverColor: "primary lighten-1",
    icon: "mdi-credit-card-chip-outline",
    method: "Factura con el servicio MPay2",
    title: "M-PAY V2",
    dark: true,
  },
  {
    code: paymentMethodsCodes[7],
    color: "primary",
    hoverColor: "primary lighten-1",
    icon: "mdi-credit-card-chip-outline",
    method: "Factura con el servicio MPay2",
    title: "M-PAY V2",
    dark: true,
  },
  {
    code: paymentMethodsCodes[8],
    color: "primary",
    hoverColor: "primary lighten-1",
    icon: "mdi-file-alert-outline",
    method: "Pagos recurrentes",
    title: "Cuenta",
    dark: true,
  },
  {
    code: paymentMethodsCodes[10],
    color: "grey lighten-2",
    hoverColor: "grey lighten-3",
    icon: "mdi-help-circle-outline",
    method: "Aviso de Cobranza",
    title: "Desconocido",
    dark: false,
  },
];

export const paymentStatusCodes = {
  1: "PENDIENTE",
  2: "ACEPTADO",
  3: "RECHAZADO",
  4: "DADO_DE_BAJA",
  5: "ERROR",
  10: "DEFAULT",
};

export const paymentStatus = [
  { code: paymentStatusCodes[1], text: "En proceso", color: "warning" },
  { code: paymentStatusCodes[2], text: "Aceptado", color: "success" },
  { code: paymentStatusCodes[3], text: "Rechazado", color: "error" },
  { code: paymentStatusCodes[4], text: "Cancelado", color: "grey" },
  { code: paymentStatusCodes[5], text: "Error", color: "error" },
  {
    code: paymentStatusCodes[10],
    text: "Desconocido",
    color: "grey lighten-1",
  },
];

export default {
  paymentMethodsCodes,
  paymentMethods,
  paymentStatusCodes,
  paymentStatus,
};

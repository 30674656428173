<template>
  <v-container>
    <div
      style="height: 90vh"
      class="d-flex flex-column align-center justify-center"
    >
      <v-img
        src="@/assets/rafiki.png"
        max-width="450"
        max-height="250"
        contain
      />

      <h1 class="title-4 primary--text">{{ selected?.title ?? "" }}</h1>
      <p class="subtitle-1">{{ selected?.description ?? "" }}</p>

      <div class="text-center">
        <v-btn color="primary" text @click="logout()">
          <v-icon left>mdi-logout</v-icon>
          Salir
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
import { errorLayoutData } from "@/store/resources/errors.js";
export default {
  name: "ErrorLayout",
  computed: {
    errorPageList: () => errorLayoutData,
    codigo() {
      return this.$route.query?.codigo ?? 1;
    },
    selected() {
      return this.errorPageList.find((e) => e.id == (this.codigo ?? 1)) ?? {};
    },
  },
  methods: {
    async logout() {
      this.$router.push({ name: "Logout" });
    },
  },
};
</script>
